html{
	position: absolute;
	min-width: 100%;
}

a, [data-toggle="modal"]{
	cursor: pointer;
}

#wrapper-all{
	min-width: 1200px;
}

#fixed-safe-seal{
	position: fixed;
    left: 0;
    bottom: 0;
}

.show_afterExitPop{display: none;}

.afterExitPop{	
	.show_afterExitPop{
		display: block;
	}

	.hide_afterExitPop{
		display: none;
	}
}

.form-control{
	&::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder{
		opacity:  1;

		color: #808080;
	}

	&.has-not-been-changed{
		color: #808080;
	}
}

/*** VALIDATION ***/
label.invalid{
    color: red !important;
}

input.valid, select.valid, textarea.valid {
    border-color: #a4cb83 !important;
}
input.invalid, select.invalid, textarea.invalid{
    border-color: #fac5c5 !important;
}

/*** Recaptcha Badge ***/
.grecaptcha-badge {
    transition: none !important;
    position: inherit !important;
    display: inline-block !important;
    margin: 20px 0 0;
}