#lp-header{
	position: relative;	

	background-image: url(../images/lp-header-bg.jpg);
	background-position: 50% 0%;
	background-repeat: no-repeat;

	height: 684px;

	.header-banner-content{
		float: right;
		position: relative;
		top: 350px;

		width: 407px;
	}

	#header-banner-list{
		list-style: none;
		padding: 0;
		margin: 0;
		padding-left: 30px;
		margin-bottom: 20px;

		font-family: 'Oxygen', sans-serif;
		font-weight: bold;
		font-size: 19px;

		li{
			margin-bottom: 10px;
		
			&:before{
				content: ' ';
				display: inline-block;
				background-image: url(../images/lp-header-check.png);
				
				width: 21px;
				height: 21px;
				margin-right: 5px;

				position: relative;
				top: 4px;
			}
		}
	}

	#header-arrow-spot{

		#the-arrow{
			position: relative;
			width: 390px;

			padding-top: 7px;
			padding-bottom: 7px;
			padding-left: 25px;
			
			background-color: #7f38a6;
			
			font-family: 'Oxygen', sans-serif;
			color: #fff;

			.text1{
				font-weight: bold;
				font-size: 29px;
                text-transform: uppercase;
                line-height: 32px;
			}

			.text2{
				font-size: 16px;
			}

			&:after{
				content: ' ';
				display: inline-block;
				width: 0; 
				height: 0; 
				position: absolute;
				top: 0;
				right: -34px;
				border-top: 34px solid transparent;
				border-bottom: 34px solid transparent;
				border-left: 34px solid #7f38a6;
			}
		}

		.alert-string{
			margin-left: 25px;
			padding-top: 5px;
			padding-bottom: 5px;

			font-family: 'Source Sans Pro', sans-serif;
			font-weight: 600;
			font-size: 17px;
			font-style: italic;

			color: #000;

			&:before{
				content: ' ';
				display: inline-block;
				background-image: url(../images/alert-icon.png);
				width: 21px;
				height: 17px;
				margin-right: 5px;
				vertical-align: middle;
			}
		}

		.required-message{
			margin-top: 10px;
			margin-left: 25px;

			font-family: 'Source Sans Pro', sans-serif;
			font-style: italic;
			font-size: 15px;
			color: #000;
		}

	}

	#frontpage_form{	
		border-radius: 7px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.35);

		float: right;

		width: 370px;
		//height: 618px;

		margin-top: 30px;
		// padding-bottom: 15px;

		> .inner-top{padding: 23px; padding-bottom: 0px;}
		> .inner{padding: 0px 35px;}
		> .inner-bottom{padding: 0px 35px; padding-bottom: 21px;}
		
		.heading{
			font-family: 'Montserrat', sans-serif;
			font-weight: bold;
			font-size: 25px;
			color: #2d2d2d;

			margin: 0;
			text-align: center;
		}

		.sub-heading{
			font-family: 'Montserrat', sans-serif;
			font-weight: bold;
			font-size: 32px;
			color: #38cbf0; 

			margin: 0;
			text-align: center;
			text-transform: uppercase;

			margin-bottom: 15px;
		}

		[type=submit]{
			margin-bottom: 15px;
		}

		.form-group{
			margin-bottom: 10px;
		}
	}
}
