body{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 15px;
	color: #2d2d2d;
	overflow-x: hidden;
}


.container{width: 1200px;}

.col-600{
	width: 600px;
}

.form-control{
	border-style: solid;
	border-width: 1px;
	border-color: rgb(229, 229, 229);
	background-color: rgb(255, 255, 255);

	font-family: 'Source Sans Pro', sans-serif;
	font-size: 15px;

	height: auto;
	padding: 7px 12px;
}

.btn{
	border-radius: 5px;
	background-color: #4fa137;
	
	border-width: 0;
	border-color: #fff;

	height: 56px;
	padding-top: 10px;
	padding-bottom: 10px;

	color: #fff;

	font-family: 'Oxygen', sans-serif;
	font-weight: bold;
    font-size: 26px;
    box-shadow: 0 5px 0 #247d09;

	&:hover, &:focus{
		color: #fff;
		outline: 0;
	}

	&.btn-no-thanks{
		border-radius: 3px;
		background-color: #cacaca;

		width: 168px;	

		font-size: 15px;

		padding-top: 8px;
		padding-bottom: 8px;

		height: 37px;
    }
    
    .btn-arrow{
        margin: 14px 0 0 33px;
        position: absolute;
    }
}




