section#the-dangers-of-cortisol{
	padding-bottom: 60px;

	.heading{
		span:nth-child(1){
			font-family: 'Montserrat', sans-serif;
			font-weight: normal;
			font-size: 50px;
		}

		span:nth-child(2){
			font-family: 'Lora', serif;
			font-weight: normal;
			font-style: italic;
			font-size: 50px;

			color: #ee1010;
		}

		margin: 0;
		margin-bottom: 25px;
	}

	ul{
		list-style: none;
		padding: 0;
		margin: 0;

		li{
			&:before{
				content: ' ';
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 2px;
				margin-right: 10px;
				width: 7px;
				height: 7px;
				background-color: rgb(237, 0, 0);
				border-radius: 100%;
			}
		}
	}
}

section#how-can-it-help-you{
	background-image: url(../images/how-can-it-help-bg.jpg);
	background-position: 50% 0%;
	background-repeat: no-repeat;

	height: 539px;

	.heading{
		font-family: 'Montserrat', sans-serif;
		font-size: 50px;
	
		margin: 0;
		margin-top: 50px;
	}

	.sub-heading{
		font-family: 'Lora', serif;
		font-style: italic;
		font-size: 53px;

		color: #38cbf0;

		margin: 0;
		margin-bottom: 25px;
	}

	ul{
		list-style: none;
		padding: 0;
		margin: 0;
	
		li{
			margin-bottom: 10px;

			&:before{
				content: ' ';
				display: inline-block;
				vertical-align: middle;
				width: 38px;
				height: 38px;
				margin-right: 12px;
			}
		}
	}

	.features-one{
		@for $i from 1 through 4 {
			li:nth-child(#{$i}):before {
				background-image: url(../images/how_icons1.png);
				background-position: 38px * $i 0;
			}
		}
	}

	.features-two{
		@for $i from 1 through 3 {
			li:nth-child(#{$i}):before {
				background-image: url(../images/how_icons2.png);
				background-position: 38px * $i 0;
			}
		}
	}
}

section.in-between-banner{
	background-image: url(../images/in-between-banner-bg.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0%;
	
	height: 96px;

	.inner{
		padding-top: 20px;
		padding-left: 220px;
	}

	.heading{
		font-family: 'Montserrat', sans-serif;
		font-size: 29px;
		color: rgb(0, 107, 141);

		text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.004);
	
		margin: 0;
		margin-bottom: 5px;

        display: inline-block;
        font-weight: 700;
        letter-spacing: -1px;
        text-shadow: 2px 2px #fff;
	}

	.sub-heading{
		font-family: 'Oxygen', sans-serif;
		font-weight: bold;

		margin: 0;
	
		display: inline-block;
	    width: 565px;

        text-align: center;
        font-size: 15px;
        color: #000;
	}

	.btn{
		position: relative;
		left: -35px;
		width: 300px;

		box-shadow: 0 5px 0 #247d09, 0 7px 5px 2.5px rgba(255,255,255,0.75);
	}
}

section#stress-and-sleep{
	padding-top: 45px;

	.heading{
		font-family: 'Lora', serif;
		font-style: italic;
		font-size: 53px;

		color: #38cbf0;

		margin: 0;
		// margin-bottom: 25px;
	}

	.sub-heading{
		font-family: 'Montserrat', sans-serif;
		font-size: 50px;
	
		margin: 0;
		margin-bottom: 36px;
	}

	.short-description{
		font-family: 'Oxygen', sans-serif; 
		font-size: 20px; 
		font-weight: bold; 
		color: #2d2d2d; 
		margin-top: 36px; margin-bottom: 36px;
	}
}

section#how-it-works{
	padding-bottom: 50px;

	.heading{
		font-family: 'Lora', serif;
		font-style: italic;
		font-size: 53px;

		color: #38cbf0;

		margin: 0;
		// margin-bottom: 25px;
	}

	.sub-heading{
		font-family: 'Montserrat', sans-serif;
		font-size: 50px;
	
		margin: 0;
		margin-bottom: 36px;
	}

	.short-description{
		font-family: 'Oxygen', sans-serif; 
		font-size: 20px; 
		font-weight: bold; 
		color: #2d2d2d; 
		margin-top: 36px; margin-bottom: 36px;
	}
	
	.how-it-points{
		.col{
			&:before{
				content: ' ';
				width: 70px;
				height: 70px;
				display: block;
				margin: auto;
				margin-bottom: 20px;
			}

			text-align: center;
			font-family: 'SegoePrintBold';
		}

		@for $i from 1 through 4 {
			.col:nth-child(#{$i}):before {
				background-image: url(../images/icons_bg.png);
				background-position: 90px * $i 0;
				
			}
		}

		.col:nth-child(1):before{width: 89px; height: 70px;}
		.col:nth-child(4):before{width: 70px; height: 70px;}
	}
}

section#success-stories{
	background-color: #f6f6f6;

	.container{
		background-image: url(../images/success-stories-container-bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;

		height: 595px;

		position: relative;
	}

	.quote-mark-left, .quote-mark-right{
		position: absolute;
		top: 50%;

		font-family: "Times New Roman", Times, serif;
		font-weight: bold;

		font-size: 206px;
		color: #c3c3c3;
		opacity: .5;

		width: 103px;
		height: 75px;
		line-height: 140px;
	}

	.quote-mark-left{
		left: -103px - 64px;
	}
	
	.quote-mark-right{
		right: -103px - 64px;
	}

	.heading{
		text-align: center;

		margin: 0;
		margin-top: 50px;
		margin-bottom: 30px;

		span:nth-child(1){
			font-family: 'Montserrat', sans-serif;
			font-weight: normal;
			font-size: 50px;
		}

		span:nth-child(2){
			font-family: 'Lora', serif;
			font-style: italic;
			font-size: 53px;

			color: #38cbf0;
		}
	}

	.success-story{
		text-align: center;

		.heading{
			font-family: 'Oxygen', sans-serif;
			font-weight: bold;
			font-size: 20px;
			
			color: #2d2d2d;
		}

		.who{
			display: block;

			font-family: 'Oxygen', sans-serif;
			font-weight: bold;
			font-size: 15px;

			color: #2d2d2d;
		}
		.ss-icon{
			background: url(../images/ppl.png);
			width: 125px;
			height: 125px;
			margin: 0 auto;
		}

		@for $i from 1 through 3{
			.ss#{$i}-icon{
				background-position: (125px * $i) 0;
			}
		}
	}
}

section#ingredients{
	background-image: url(../images/ingredient-repeated-bg.jpg);
	background-repeat: repeat;

	padding-top: 45px;
	padding-bottom: 30px;

	.heading{
		font-family: 'Montserrat', sans-serif;
		font-size: 50px;
	
		margin: 0;
	}

	.sub-heading{
		font-family: 'Lora', serif;
		font-style: italic;
		font-size: 53px;

		color: #38cbf0;

		margin: 0;
	}

	.ingredient{
		font-size: 15px;
		position: relative;

		.heading{
			font-family: 'Oxygen', sans-serif;
			font-weight: bold;
			font-size: 20px;

			color: #2d2d2d;
			margin: 0;
			margin-bottom: 15px;
		}
	
		img, .text{
			position: absolute;
		}

		.text p{line-height: 1.667;}

		&.img-right{
			img{right: 10px;}
			.text{left: 10px;}
		}

		&.img-left{
			img{left: 10px;}
			.text{right: 10px;}
		}
	}
}

section#freedom{
	background-image: url(../images/freedom-bg.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0%;

	height: 557px;

	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
	font-size: 15px;

	.container > .inner{
		width: 545px;

		position: relative;
		top: 215px;
		left: 52%;
	}

	.how-it-points{
		list-style: none;
		padding: 0;
		margin: 0;

		margin-top: 30px;
		margin-bottom: 30px;
	
		li{
			display: inline-block;
			width: 110px;

			padding: 10px 0px;

			background-color: rgb(255, 255, 255);
			box-shadow: 0px 2px 5px 0px rgba(0, 1, 1, 0.24);

			margin-right: 10px;

			text-align: center;

			  line-height: 1;

			&:before{
				content: ' ';
				width: 54px;
				height: 54px;
				display: block;
				margin: auto;	
				margin-bottom: 8px;
			}
			
			&:nth-child(1):before{width: 72px;} 
		}


		@for $i from 1 through 4 {
			li:nth-child(#{$i}):before {
				background-image: url(../images/icons_sm.png);
				background-position: 72px * $i 0;
			}
		}
	}

	.btn{
		width: 300px;
	    position: relative;

	   	.hurry{
	   		position: absolute;
	   		bottom: 10px;
	   		right: -35px;
	   	}
	}
}
body#ty{
	margin: 0;
	
	.head{
		background: #41C8FF;
	    color: #fff;

		h1{
		    padding: 10px;
		    text-align: center;
		}
	}

	.content{
		margin: 0 auto;
		width: 50%;
	}
}

.flash-sale{
    width: 1172px;
    margin: 0 auto;
    display: flex;

    &__img{
        margin-right: 20px;
        margin-left: 177px;
    }

    &__text{
        margin-top: 11px;
        line-height: 19px;
        font: 700 16px 'Oxygen', sans-serif;
    }

    &__watch{
        margin: 0 4px 0 10px;
    }

    #counter{
        color: #f33e3e;
    }
}