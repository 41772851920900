footer#lp-footer{
	a{
		cursor: pointer;
	}

	.part-one{
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 15px;
		color: #000;

		padding-top: 30px;
		padding-bottom: 30px;

		*[class*="col-"]{
			text-align: center;

			.heading{font-weight: 600; text-transform: uppercase; margin: 0; margin-bottom: 15px;}
		}

		.social > nav > a{
			margin-left: 10px;
			
			&:first-of-type{margin-left: 0;}
		}

		.cards, .secure{
			nav > img{
				margin-right: 15px;
				
				&:first-of-type{margin-left: 0;}
			}
		}
	}

	.part-two{
		border-top: 1px solid #d4d4d4;
		padding-top: 20px;
		padding-bottom: 20px;

		nav{
			margin-bottom: 15px;

			> a{
				color: #000;
		
				text-decoration:none;

				&:before{
					content: "|";
					display: inline-block;
					margin-left: 1em;
					margin-right: 1em;
				}

				&:first-of-type:before{
					content: '';
					margin: 0;
				}


			}
		}
	}
}
